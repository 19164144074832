var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aFormItem',{staticClass:"hf-wrapper hf-input-wrapper hf-typed-date"},[(_vm.label)?_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),(_vm.description)?_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.description))]):_vm._e(),_vm._v(" "),_c('aInput',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
      _vm.fieldKey,
      {
        initialValue: _vm.initialValue,
        rules: _vm.validationRules,
      },
    ]),expression:"[\n      fieldKey,\n      {\n        initialValue,\n        rules: validationRules,\n      },\n    ]"}],staticClass:"hf-input",class:_vm.fieldClass,attrs:{"type":_vm.type,"placeholder":_vm.placeholder || 'xx/xx/xxxx'},on:{"blur":_vm.onBlur,"change":_vm.onChange},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('a-icon',{attrs:{"type":"field-svg"}})]},proxy:true},{key:"suffix",fn:function(){return [_c('a-date-picker',{on:{"change":_vm.onChangeDatePicker},model:{value:(_vm.datepickerValue),callback:function ($$v) {_vm.datepickerValue=$$v},expression:"datepickerValue"}},[_c('aTooltip',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Digite a data no campo ")]},proxy:true}])},[_vm._v(" "),_c('a-icon',{staticClass:"calendar c-pointer",attrs:{"type":"calendar"}})],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }